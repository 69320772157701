import Sprite from "app/components/sprite";
import appleLogo from "app/images/popover/apple-logo.png";
import googlePlayLogo from "app/images/popover/google-play-logo.png";
import whatsapp from "app/images/popover/whatsapp.png";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";

const GetZbooniPopover = ({ popover, setPopover, zbooniLink }) => {
  return popover ? (
    <div
      className="fixed left-0 top-0 z-[100] flex h-screen w-full
      items-center justify-center bg-translucentdarkgray"
    >
      <div
        className="relative h-[383px] w-full max-w-[300px]
        rounded-2xl bg-white px-7 py-8 font-montserrat text-base
        text-primary md:max-w-[519px] md:px-8 md:py-10"
      >
        <button
          className="absolute top-4 ltr:right-4 rtl:left-4"
          onClick={() => setPopover(false)}
        >
          <Sprite
            name="close-btn"
            className="h-[10px] w-[10px] md:h-[14px] md:w-[14px]"
          />
        </button>
        <div className="flex h-full flex-col justify-between">
          <p className="text-2xl font-black md:text-3xl">
            <Trans>Download Zbooni</Trans>
          </p>
          <div
            className="flex flex-col items-center
            justify-between text-white md:flex-row"
          >
            <a
              href="https://apps.apple.com/ae/app/zbooni/id1222835796"
              target="_blank"
              rel="noreferrer"
              id="cta_getZbooniPopover_downloadNow"
            >
              <button
                className="mb-3 flex h-[50px] w-[220px] items-center
                justify-center rounded-full bg-primary font-extrabold md:mb-0"
              >
                <img
                  alt=""
                  src={appleLogo}
                  className="mb-1 h-[26px] w-[21px]"
                />
                <p className="ltr:pl-2 rtl:pr-2">
                  <Trans>Download now</Trans>
                </p>
              </button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.zbooni&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
              id="cta_getZbooniPopover_downloadNow"
            >
              <button
                className="flex h-[50px] w-[220px] items-center
                justify-center rounded-full bg-primary font-extrabold"
              >
                <img
                  alt=""
                  src={googlePlayLogo}
                  className="h-[26px] w-[23px]"
                />
                <p className="ltr:pl-2 rtl:pr-2">
                  <Trans>Download now</Trans>
                </p>
              </button>
            </a>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="block h-px w-[70px] bg-gray-300 md:w-48" />
            <p className="text-sm font-bold">
              <Trans>or</Trans>
            </p>
            <span className="block h-px w-[70px] bg-gray-300 md:w-48" />
          </div>
          <p className="pt-3 text-base font-extrabold md:text-lg">
            <Trans>Reach out to our team for more information</Trans>
          </p>
          <a
            href={zbooniLink}
            target="_blank"
            rel="noreferrer"
            id="cta_whoWeServe_popover_getInTouch"
          >
            <button
              className="mx-auto flex h-[50px] w-[220px] items-center
              justify-center rounded-full bg-green-darkest
              font-extrabold text-white md:mx-0"
            >
              <img alt="" src={whatsapp} className="h-6 w-6" />
              <p className="ltr:pl-2 rtl:pr-2">
                <Trans>Get in touch</Trans>
              </p>
            </button>
          </a>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default GetZbooniPopover;

GetZbooniPopover.propTypes = {
  popover: PropTypes.bool,
  setPopover: PropTypes.func,
  zbooniLink: PropTypes.string,
};
